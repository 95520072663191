// adapted from deprecated antd global.less
body {
  font-size: 14px;
  line-height: 1.5;
  -webkit-font-smoothing: antialiased;
}
*,
*::before,
*::after {
  box-sizing: border-box;
}

html {
  --scroll-bar: 0;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
